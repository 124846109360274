import React from "react";

import {
  EntityProvidingComponentsCard,
  EntityConsumingComponentsCard,
  EntityApiDefinitionCard
} from "@backstage/plugin-api-docs";
import {
  EntityLayout,
  EntityAboutCard,
  EntityLinksCard
} from "@backstage/plugin-catalog";
import { EntityCatalogGraphCard } from "@backstage/plugin-catalog-graph";
import { Grid } from "@mui/material";

import { entityWarningContent } from "../content/EntityWarningContent";

export const apiPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid container item xs={12}>
          <Grid container item md={6} xs={12} alignContent="flex-start">
            <Grid item xs={12}>
              <EntityAboutCard />
            </Grid>
            <Grid item xs={12}>
              <EntityProvidingComponentsCard />
            </Grid>
            <Grid item xs={12}>
              <EntityConsumingComponentsCard />
            </Grid>
          </Grid>
          <Grid container item md={6} xs={12} alignContent="flex-start">
            <Grid item xs={12}>
              <EntityCatalogGraphCard height={400} />
            </Grid>
            <Grid item xs={12}>
              <EntityLinksCard />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/definition" title="Definition">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EntityApiDefinitionCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);