import React from 'react';

import { CodeSnippet, EmptyState } from '@backstage/core-components';

import { Button, Typography } from '@mui/material';

const EXAMPLE = `import { createPlugin } from '@backstage/core-plugin-api';

export default createPlugin({
  id: 'plugin-name',
  featureFlags: [{ name: 'enable-example-feature' }],
});
`;

export const EmptyFlags = () => (
  <EmptyState
    missing="content"
    title="No flags for Experimental Features"
    description="Flags for Experimental Features make it possible for plugins to register features in Backstage for users to opt into. You can use this to split out logic in your code for manual A/B testing, etc."
    action={
      <>
        <Typography variant="body1">
          An example for how to add a flag is highlighted below:
        </Typography>
        <CodeSnippet
          text={EXAMPLE}
          language="typescript"
          showLineNumbers
          highlightedNumbers={[6]}
          customStyle={{ background: 'inherit', fontSize: '115%' }}
        />
        <Button
          variant="contained"
          color="primary"
          href="https://backstage.io/docs/api/utility-apis"
        >
          Read More
        </Button>
      </>
    }
  />
);