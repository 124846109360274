import React from 'react';

import {
  EntitySwitch,
  isKind,
} from '@backstage/plugin-catalog';

import { apiPage } from './page-type/ApiPage';
import { defaultEntityPage } from './page-type/DefaultEntityPage';
import { componentPage } from './page-type/ComponentPage';
import { domainPage } from './page-type/DomainPage';
import { systemPage } from './page-type/SystemPage';
import { groupPage } from './page-type/GroupPage';
import { userPage } from './page-type/UserPage';

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={componentPage} />
    <EntitySwitch.Case if={isKind('api')} children={apiPage} />
    <EntitySwitch.Case if={isKind('group')} children={groupPage} />
    <EntitySwitch.Case if={isKind('user')} children={userPage} />
    <EntitySwitch.Case if={isKind('system')} children={systemPage} />
    <EntitySwitch.Case if={isKind('domain')} children={domainPage} />

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
