export const faqData = [
  {
    id: 1,
    question: "What is the Novo Nordisk Developer Portal?",
    answer: "The Novo Nordisk Developer Portal is a centralized platform powered by Backstage that provides developers with a unified space for resource discovery, collaboration, and code sharing. It includes key services like the Software Catalog, Software Templates, TechDocs, and Tech Radar.",
  },
  {
    id: 2,
    question: "What is the Software Catalog?",
    answer: "The Software Catalog is a key service within the Developer Portal that provides a comprehensive list of all software components, services, and libraries available within the organization. It helps developers discover and understand the software assets they can use or contribute to.",
  },
  {
    id: 3,
    question: "How do I create a new component in the Developer Portal?",
    answer: "To create a new component, you can use the Software Templates available in the Developer Portal. Navigate to the 'Create' section and select the appropriate template for your new component. Follow the guided steps to complete the creation process.",
  },
  {
    id: 4,
    question: "What are TechDocs?",
    answer: "TechDocs is a documentation service within the Developer Portal that allows teams to create, manage, and publish technical documentation. It integrates seamlessly with the Software Catalog, providing comprehensive documentation for all software components.",
  },
];
