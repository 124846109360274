/*
  Do not edit directly
  Generated on Fri, 12 Apr 2024 12:55:36 GMT
*/

export const tokens = {
  "colorPalettes": {
    "graniteGrey1000": "#1d1e21",
    "graniteGrey900": "#3a3d42",
    "graniteGrey800": "#585c64",
    "graniteGrey700": "#757b85",
    "graniteGrey600": "#939aa7",
    "graniteGrey500": "#a8aeb8",
    "graniteGrey400": "#bec2ca",
    "graniteGrey300": "#d3d6db",
    "graniteGrey200": "#e9eaed",
    "graniteGrey100": "#f7f8f9",
    "sandGrey1000": "#282725",
    "sandGrey900": "#514e4b",
    "sandGrey800": "#7a7671",
    "sandGrey700": "#a39d97",
    "sandGrey600": "#ccc5bd",
    "sandGrey500": "#d6d0ca",
    "sandGrey400": "#e0dcd7",
    "sandGrey300": "#eae7e4",
    "sandGrey200": "#f4f3f1",
    "sandGrey100": "#fbfbfa",
    "goldenSun1000": "#2e2200",
    "goldenSun900": "#5d4400",
    "goldenSun800": "#8c6600",
    "goldenSun700": "#bb8800",
    "goldenSun600": "#eaab00",
    "goldenSun500": "#eebb33",
    "goldenSun400": "#f2cc66",
    "goldenSun300": "#f6dd99",
    "goldenSun200": "#faeecc",
    "goldenSun100": "#fdf9ee",
    "lavaRed1000": "#2d100c",
    "lavaRed900": "#5b2119",
    "lavaRed800": "#8a3325",
    "lavaRed700": "#b84432",
    "lavaRed600": "#e6553f",
    "lavaRed500": "#eb7765",
    "lavaRed400": "#f0998b",
    "lavaRed300": "#f5bbb2",
    "lavaRed200": "#faddd8",
    "lavaRed100": "#fdf3f2",
    "rosePink1000": "#2f2126",
    "rosePink900": "#5f424c",
    "rosePink800": "#8e6472",
    "rosePink700": "#be8598",
    "rosePink600": "#eea7bf",
    "rosePink500": "#f1b8cb",
    "rosePink400": "#f4cad8",
    "rosePink300": "#f8dbe5",
    "rosePink200": "#fbedf2",
    "rosePink100": "#fdf9fa",
    "forestGreen1000": "#0c1f0a",
    "forestGreen900": "#193e15",
    "forestGreen800": "#255d1f",
    "forestGreen700": "#327c2a",
    "forestGreen600": "#3f9c35",
    "forestGreen500": "#65af5d",
    "forestGreen400": "#8bc385",
    "forestGreen300": "#b2d7ae",
    "forestGreen200": "#d8ebd6",
    "forestGreen100": "#f2f8f1",
    "oceanGreen1000": "#081c1b",
    "oceanGreen900": "#103937",
    "oceanGreen800": "#195753",
    "oceanGreen700": "#21746f",
    "oceanGreen600": "#2a918b",
    "oceanGreen500": "#54a7a2",
    "oceanGreen400": "#7fbdb9",
    "oceanGreen300": "#a9d3d0",
    "oceanGreen200": "#d4e9e7",
    "oceanGreen100": "#f0f7f7",
    "lightBlue1000": "#0b1e2c",
    "lightBlue900": "#173c58",
    "lightBlue800": "#235a85",
    "lightBlue700": "#2f78b1",
    "lightBlue600": "#3b97de",
    "lightBlue500": "#62abe4",
    "lightBlue400": "#89c0eb",
    "lightBlue300": "#b0d5f1",
    "lightBlue200": "#d7eaf8",
    "lightBlue100": "#f1f8fc",
    "fadedBlue1000": "#11151f",
    "fadedBlue900": "#232a3f",
    "fadedBlue800": "#36405e",
    "fadedBlue700": "#48557e",
    "fadedBlue600": "#5a6b9e",
    "fadedBlue500": "#7b88b1",
    "fadedBlue400": "#9ca6c4",
    "fadedBlue300": "#bdc3d8",
    "fadedBlue200": "#dee1eb",
    "fadedBlue100": "#f4f5f8",
    "seaBlue1000": "#001633",
    "seaBlue900": "#002c66",
    "seaBlue800": "#003b8a",
    "seaBlue700": "#0049a9",
    "seaBlue600": "#005bd2",
    "seaBlue500": "#338bff",
    "seaBlue400": "#66a8ff",
    "seaBlue300": "#99c5ff",
    "seaBlue200": "#cce2ff",
    "seaBlue100": "#e5f1ff",
    "white": "#ffffff",
    "trueBlue": "#001965"
  },
  "defaultTheme": {
    "brand": "#001965",
    "fontPrimary": "#001965",
    "black": "#001965",
    "white": "#ffffff",
    "bgLight": "#ffffff",
    "fontSecondary": "#5a6b9e",
    "disabled": "#e0dcd7",
    "disabledText": "#a39d97",
    "bgMedium": "#f7f8f9",
    "bgHeavy": "#e9eaed",
    "focus": "#89c0eb",
    "baseLight": "#f4f5f8",
    "baseOutlineHeavy": "#5a6b9e",
    "baseOutline": "#9ca6c4",
    "baseHeavy": "#bdc3d8",
    "baseMedium": "#dee1eb",
    "primaryActive": "#003b8a",
    "primaryHover": "#0049a9",
    "primary": "#005bd2",
    "primaryMedium": "#cce2ff",
    "primaryLight": "#e5f1ff",
    "primaryContent": "#ffffff",
    "secondaryActive": "#36405e",
    "secondaryHover": "#48557e",
    "secondary": "#5a6b9e",
    "secondaryMedium": "#cce2ff",
    "secondaryLight": "#e5f1ff",
    "secondaryContent": "#ffffff",
    "infoActive": "#173c58",
    "infoHover": "#235a85",
    "info": "#2f78b1",
    "infoMedium": "#89c0eb",
    "infoLight": "#d7eaf8",
    "infoContent": "#ffffff",
    "successActive": "#193e15",
    "successHover": "#255d1f",
    "success": "#327c2a",
    "successMedium": "#8bc385",
    "successLight": "#d8ebd6",
    "successContent": "#ffffff",
    "warningActive": "#8c6600",
    "warningHover": "#bb8800",
    "warning": "#eaab00",
    "warningMedium": "#f2cc66",
    "warningLight": "#faeecc",
    "warningContent": "#1d1e21",
    "alertActive": "#5b2119",
    "alertHover": "#8a3325",
    "alert": "#b84432",
    "alertMedium": "#f0998b",
    "alertLight": "#faddd8",
    "alertContent": "#ffffff",
    "accentRedStrong": "#eea7bf",
    "accentRedMedium": "#f4cad8",
    "accentRedSubtle": "#fbedf2",
    "accentGreenStrong": "#2a918b",
    "accentGreenMedium": "#7fbdb9",
    "accentGreenSubtle": "#d4e9e7",
    "accentBlueStrong": "#3b97de",
    "accentBlueMedium": "#89c0eb",
    "accentBlueSubtle": "#d7eaf8",
    "accentNeutralStrong": "#ccc5bd",
    "accentNeutralMedium": "#e0dcd7",
    "accentNeutralSubtle": "#f4f3f1"
  },
  "color": {
    "primaryFontColor": "#f2f2f2",
    "fadedBlue": "#5a6b9e",
    "fadedBlue10": "#e2e5ed",
    "fadedBlue50": "#acb5ce",
    "fadedBlue75": "#f0f2f6",
    "fadedBlue5": "#f7f8fa",
    "trueBlue100": "#001965",
    "lightBlue100": "#3b97de",
    "lavaRed": "#e5553f",
    "alertRed": "#b00020",
    "lavaRed15": "#fbe6e2",
    "lightBlue40": "#b1d5f2",
    "goldenSun": "#975a16",
    "goldenSun15": "#fcf2d9",
    "lightBlue15": "#e2f0fa",
    "forrestGreen": "#265f20",
    "forrestGreen15": "#e2f0e1",
    "seaBlue140": "#003480",
    "graniteGray140": "#585c64",
    "seaBlue75": "#ebf3ff",
    "seaBlue120": "#0045a8",
    "seaBlue15": "#d1e3ff",
    "graniteGray15": "#eff0f2",
    "graniteGray20": "#e9ebed",
    "graniteGray60": "#bec2ca",
    "seaBlue5": "#f0f6ff",
    "seaBlue100": "#0066f8",
    "graniteGray100": "#939aa7",
    "seaBlue10": "#e0ecff",
    "seaBlue40": "#84b6ff",
    "graniteGray40": "#d4d7dc",
    "oceanGreen100": "#2a918b",
    "oceanGreen40": "#aad3d1",
    "oceanGreen15": "#dfefee",
    "rosePink100": "#eea7bf",
    "sandGray100": "#ccc5bd",
    "rosePink40": "#f8dce5",
    "sandGray40": "#ebe8e5",
    "rosePink15": "#fcf2f5",
    "sandGray15": "#f7f6f5",
    "black90": "#1a1a1a",
    "white": "#ffffff",
    "black70": "#4d4d4d",
    "black50": "#808080",
    "black30": "#b3b3b3",
    "black20": "#cccccc",
    "black10": "#e6e6e6",
    "black5": "#f2f2f2",
    "black": "#f2f2f2"
  }
};
