import React from 'react';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import { InfoCard } from '@backstage/core-components';

const StyledInfoCard = styled(InfoCard)({
  padding: '20px',
});

const StyledButtonsContainer = styled(Grid)({
  padding: '12px 0',
});

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  width: '220px',
  textAlign: 'center',
  fontSize: '13px',
}));

const buttonsData = [
  {
    id: 1,
    text: 'register your repository',
    href: '../create/templates/default/developer-portal-onboarding',
  },
  {
    id: 2,
    text: 'view documentation',
    href: '../docs/default/component/dev-docs/developer-guides/onboarding-to-developer-portal/quick-onboard-to-software-catalog/',
  },
];

const HomePageInfoBoxDeprecated = () => {
  return (
    <StyledInfoCard title="Welcome to our Novo Nordisk Developer Portal!">
      <p>Our Developer Portal, powered by Backstage, is the ultimate hub for developers, offering a unified space for easy resource discovery, seamless collaboration, and efficient code sharing.</p>
      <h3>Highlights of the Developer Portal</h3>
      <ul>
        <li>
          <h4>Streamlined Collaboration:</h4>
          <p>Our Backstage-powered portal fosters seamless collaboration and efficient code sharing.</p>
        </li>
        <li>
          <h4>Enhanced Productivity:</h4>
          <p>From junior to senior developers, our portal elevates workflow and productivity.</p>
        </li>
        <li>
          <h4>Key Services:</h4>
          <p>Prioritized services include Software Catalog, Software Templates and Plugins (Golden Paths), and TechDocs</p>
        </li>
      </ul>
      <StyledButtonsContainer
        container
        item
        xs={12}
        spacing={2}
        alignItems="center"
        direction="column"
      >
        <Grid>
          <h2>Developer Portal Onboarding</h2>
        </Grid>
        <Grid container justifyContent="center" spacing={2}>
          {buttonsData.map((button) => (
            <Grid item key={button.id}>
              <StyledButton
                variant="contained"
                href={button.href}
              >
                {button.text}
              </StyledButton>
            </Grid>
          ))}
        </Grid>
      </StyledButtonsContainer>
    </StyledInfoCard>
  );
};

export default HomePageInfoBoxDeprecated;
