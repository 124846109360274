import React from 'react';

import {
  SettingsLayoutRouteProps,
  SettingsLayout,
  UserSettingsGeneral,
  UserSettingsAuthProviders
} from '@backstage/plugin-user-settings';

import { UserSettingsFeatureFlags } from './FeatureFlags/UserSettingsFeatureFlags';

export const SettingsPage = (props: {
  tabs?: React.ReactElement<SettingsLayoutRouteProps>[];
  providerSettings?: JSX.Element;
}) => {
  const { providerSettings, tabs } = props;

  return (
    <SettingsLayout>
      <SettingsLayout.Route path="general" title="General">
        <UserSettingsGeneral />
      </SettingsLayout.Route>
      <SettingsLayout.Route
        path="auth-providers"
        title="Authentication Providers"
      >
        <UserSettingsAuthProviders providerSettings={providerSettings} />
      </SettingsLayout.Route>
      <SettingsLayout.Route path="feature-flags" title="Experimental Features">
        <UserSettingsFeatureFlags />
      </SettingsLayout.Route>
      {tabs}
    </SettingsLayout>
  );
};