import React, { useCallback, useState } from 'react';

import { Grid, Typography, Box, TextField, IconButton, List, styled } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import { EmptyFlags } from './EmptyFlags';
import { FlagItem } from './FeatureFlagsItem';

import {
  FeatureFlag,
  FeatureFlagsApi,
  featureFlagsApiRef,
  FeatureFlagState,
  useApi,
} from '@backstage/core-plugin-api';
import { InfoCard, WarningIcon } from '@backstage/core-components';

const StyledBox = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  width: 'fit-content',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginTop: theme.spacing(2),
}));

const StyledTextField = styled(TextField)(() => ({
  display: 'flex',
  justifyContent: 'flex-end'
}));

export const sortFlags = (
  flags: FeatureFlag[],
  featureFlagsApi: FeatureFlagsApi,
): FeatureFlag[] => {
  const activeFlags = flags.filter(flag => featureFlagsApi.isActive(flag.name));
  const idleFlags = flags.filter(flag => !featureFlagsApi.isActive(flag.name));
  return [...activeFlags, ...idleFlags];
};

/** @public */
export const UserSettingsFeatureFlags = () => {
  const featureFlagsApi = useApi(featureFlagsApiRef);
  const inputRef = React.useRef<HTMLElement>();

  const initialFeatureFlags = featureFlagsApi.getRegisteredFlags();
  const initialFeatureFlagsSorted = sortFlags(
    initialFeatureFlags,
    featureFlagsApi,
  );
  const [featureFlags] = useState(initialFeatureFlagsSorted);

  const initialFlagState = Object.fromEntries(
    featureFlags.map(({ name }) => [name, featureFlagsApi.isActive(name)]),
  );

  const [state, setState] = useState<Record<string, boolean>>(initialFlagState);
  const [filterInput, setFilterInput] = useState<string>('');

  const toggleFlag = useCallback(
    (flagName: string) => {
      const newState = featureFlagsApi.isActive(flagName)
        ? FeatureFlagState.None
        : FeatureFlagState.Active;

      featureFlagsApi.save({
        states: { [flagName]: newState },
        merge: true,
      });

      setState(prevState => ({
        ...prevState,
        [flagName]: newState === FeatureFlagState.Active,
      }));
    },
    [featureFlagsApi],
  );

  if (!featureFlags.length) {
    return <EmptyFlags />;
  }

  const clearFilterInput = () => {
    setFilterInput('');
    inputRef?.current?.focus();
  };

  const filteredFeatureFlags = featureFlags.filter(featureFlag => {
    const featureFlagName = featureFlag.name.toLocaleLowerCase('en-US');
    return featureFlagName.includes(filterInput.toLocaleLowerCase('en-US'));
  });

  const Header = () => (
    <Grid container style={{ justifyContent: 'space-between' }}>
      <Grid item xs={6} md={8}>
        <Typography variant="h5">Experimental Features</Typography>
        <Typography variant="subtitle1">
          Please refresh the page when toggling features to see the changes
        </Typography>
      </Grid>
      {featureFlags.length >= 10 && (
        <Grid item xs={6} md={4}>
          <StyledTextField
            label="Filter"
            inputRef={ref => ref && ref.focus()}
            InputProps={{
              ...(filterInput.length && {
                endAdornment: (
                  <IconButton
                    aria-label="Clear filter"
                    onClick={clearFilterInput}
                    edge="end"
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }),
            }}
            onChange={e => setFilterInput(e.target.value)}
            value={filterInput}
          />
        </Grid>
      )}
    </Grid>
  );

  return (
    <InfoCard title={<Header />}>
      <List dense>
        {filteredFeatureFlags.map(featureFlag => {
          const enabled = Boolean(state[featureFlag.name]);

          return (
            <FlagItem
              key={featureFlag.name}
              flag={featureFlag}
              enabled={enabled}
              toggleHandler={toggleFlag}
            />
          );
        })}
      </List>
      <StyledBox>
          <WarningIcon fontSize="small" />
          <Typography variant="subtitle1">
            Remember that these features are experimental and may not be fully functional
          </Typography>
        </StyledBox>
    </InfoCard>
  );
};