import React from 'react';

import { Content, Header, Page } from '@backstage/core-components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';

import { faqData } from './faqData';
import Box from '@mui/material/Box';

const FaqPage = () => {
  return (
    <Page themeId="documentation">
      <Header title="Frequently Asked Questions" />
      <Content>
        <Box py={4} px={15}>
          {faqData.map((faq) => (
            <Accordion key={faq.id}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">{faq.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{faq.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Content>
    </Page>
  );
};

export default FaqPage;