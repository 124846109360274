import React from "react";

import {
  EntitySwitch,
  isComponentType
} from "@backstage/plugin-catalog";

import { defaultEntityPage } from "./DefaultEntityPage";
import { serviceEntityPage } from "./ServiceEntityPage";
import { websiteEntityPage } from "./WebsiteEntityPage";

export const componentPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType('service')}>
      {serviceEntityPage}
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isComponentType('website')}>
      {websiteEntityPage}
    </EntitySwitch.Case>
    <EntitySwitch.Case>
      {defaultEntityPage}
    </EntitySwitch.Case>
  </EntitySwitch>
);
