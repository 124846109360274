import { EntityLinguistCard } from "@backstage-community/plugin-linguist";
import { Entity } from "@backstage/catalog-model";
import { EntitySwitch } from "@backstage/plugin-catalog";
import { Grid } from "@mui/material";
import React from "react";

export const shouldDisplayLinguist = (entity : Entity) => !!(
  entity.metadata.annotations && 
  entity.metadata.annotations["backstage.io/source-location"] &&
  (entity.kind === "Component"|| entity.kind === 'System')
);

export const linguistEntityContent = (
  <EntitySwitch>
    <EntitySwitch.Case if={shouldDisplayLinguist}> 
      <Grid item xs={12}>
        <EntityLinguistCard />
      </Grid>
    </EntitySwitch.Case>
  </EntitySwitch>
);