import React from 'react';

import ReactPlayer from 'react-player';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import { InfoCard } from '@backstage/core-components';

const StyledInfoCard = styled(InfoCard)({
  padding: '20px',
  width: '100%',
});

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  width: '220px',
  textAlign: 'center',
  fontSize: '13px',
}));

const StyledH4 = styled('h3')({
  margin: '2px 0',
});

const StyledParagraph = styled('p')({
  fontSize: '20px',
  paddingRight: '18px',
});

const StyledReactPlayer = styled(ReactPlayer)({
  position: 'absolute',
  boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
});

const StyledInlineButton = styled(Button)({
  fontSize: '16px',
  paddingTop: '4px',
});

const buttonsData = [
  {
    id: 1,
    text: 'register your repository',
    href: '../create/templates/default/developer-portal-onboarding',
  },
  {
    id: 2,
    text: 'view documentation',
    href: '../docs/default/component/dev-docs/developer-guides/onboarding-to-developer-portal/quick-onboard-to-software-catalog/',
  },
];

const HomePageInfoBox = () => {
  return (
    <StyledInfoCard title="Welcome to Novo Nordisk Developer Portal!">
      <Grid container direction="row">
        <Grid item lg={6} xs={12}>
          <Grid container item px={2}>
            <StyledParagraph>
              <b>Developer Portal</b> (also called <b>Midgard</b> internally), powered by <b>Backstage</b>, is the ultimate hub for developers, offering:
            </StyledParagraph>
            <ul>
              <li>
                <StyledH4>
                  Discoverability: 
                  <StyledInlineButton variant="text" href="../catalog">Software Catalog</StyledInlineButton>
                </StyledH4>
              </li>
              <li>
                <StyledH4>
                  Technical Documentation: 
                  <StyledInlineButton variant="text" href="../docs">TechDocs</StyledInlineButton>
                </StyledH4>
              </li>
              <li>
                <StyledH4>
                  Reuse and Good Practices: 
                  <StyledInlineButton variant="text" href="../create">Software Templates (Golden Paths)</StyledInlineButton>
                </StyledH4>
              </li>
            </ul>
          </Grid>
          <Grid
            container item py={6}
            alignItems="center"
            direction="column"
            justifyContent="center"
          >
            <h2>Developer Portal Onboarding</h2>
            <Grid container justifyContent="center" spacing={2}>
              {buttonsData.map((button) => (
                <Grid item key={button.id}>
                  <StyledButton
                    variant="contained"
                    href={button.href}
                  >
                    {button.text}
                  </StyledButton>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6} xs={12} container justifyContent="center" alignItems="center">
          <Grid item style={{ position: 'relative', paddingTop: '56.25%', width: '100%' }}>
            <StyledReactPlayer
              url="https://www.youtube.com/watch?v=85TQEpNCaU0"
              controls
              width="100%"
              height="100%"
              style={{ top: 10, left: 10 }}
              light={true}
            />
          </Grid>
        </Grid>
      </Grid>
    </StyledInfoCard>
  );
};

export default HomePageInfoBox;
