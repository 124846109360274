import React from "react";

import { EntityLayout } from "@backstage/plugin-catalog";

import { overviewContent } from "../content/OverviewContent";
import { techdocsContent } from "../content/TechDocsContent";

export const defaultEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
  </EntityLayout>
);