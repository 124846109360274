import {
  createPlugin,
  createApiFactory,
  discoveryApiRef,
  fetchApiRef,
  createComponentExtension,
} from '@backstage/core-plugin-api';
import { novoaccessApiRef, NovoAccessClient } from './api';

export const novoaccessPlugin = createPlugin({
  id: 'novoaccess',
  apis: [
    createApiFactory({
      api: novoaccessApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ discoveryApi, fetchApi }) =>
        new NovoAccessClient({ discoveryApi, fetchApi }),
    }),
  ],
});

export const EntityNovoAccessCard = novoaccessPlugin.provide(
  createComponentExtension({
    name: 'EntityNovoAccessCard',
    component: {
      lazy: () => import('./components/NovoAccessCard').then(m => m.NovoAccessCard),
    },
  }),
);
