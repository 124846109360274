import { tokens } from './nn_map';

// color codes for 'tokens' object can be found directly in the nn_map.js file or
// here: https://dev.azure.com/novonordiskit/Design%20System/_git/novo-design-tokens?path=/dist/js/nn_map.js
// we have moved tokens (only the ones that we are using) from the package to the repo due to many security/auth issues
// we are not using them heavily so there was no need to keep such package

export const novoDefaultTheme = tokens.defaultTheme;
export const novoFontColor = tokens.color;
export const novoColorPalettes = tokens.colorPalettes;
