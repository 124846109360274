import React from "react"

import { Box } from "@mui/material"
import ConstructionIcon from "@mui/icons-material/Construction"

import { HomePageInfoCard } from "../styled"

export const DiscoverCard = () => (
  <HomePageInfoCard title="Discover">
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
    >
      <h3>Explore trending and new projects</h3>
      <ConstructionIcon sx={{ fontSize: 80 }} />
      <br />
      <h4>Stay tuned, this section is under development</h4>
    </Box>
  </HomePageInfoCard>
)